<template>
  <page>
    <div class="ruleConfig">
      <span>门店采购订单直接同步给供应商</span>
      <el-switch
        v-auth="'middleman:updateOrderSync'"
        style="margin-left: 10px"
        v-model="payConfig"
        :active-value="true"
        :inactive-value="false"
        @change="switchHandler"
      >
      </el-switch>
    </div>
  </page>
</template>

<script>
export default {
  data() {
    return {
      payConfig: true
    }
  },
  created() {
    this.getConfigInfo()
  },

  methods: {
    async getConfigInfo() {
      try {
        const res = await this.$api.ruleConfig.getConfigInfo()
        this.payConfig = res.orderSync == 1 ? true : false
      } catch (e) {
        console.log(e)
      }
    },
    switchHandler(val) {
      const txt = val
        ? '启用后，待配送的订单将自动同步给供应商，是否确认要开启？'
        : '停用后，接取的订单可自行手动处理，可选择自行配送订单，或将订单推送给供应商，是否确认要停用？'

      this.$confirm(`${txt}`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(async () => {
          try {
            await this.$api.ruleConfig.operate({
              orderSyncToSupplier: val ? 1 : 0
            })
            this.$message.success('操作成功！')
          } catch (e) {
            this.payConfig = !val
            console.log(e)
          }
        })
        .catch(() => {
          this.payConfig = !val
        })
    }
  }
}
</script>

<style scoped lang="scss">
.ruleConfig {
  display: flex;
}
</style>
